"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { useState } from "react";
import { type AppRouter } from "~/server/api/root";
import { getUrl, transformer } from "./shared";
const createQueryClient = () => new QueryClient();
let clientQueryClientSingleton: QueryClient | undefined = undefined;
const getQueryClient = () => {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return createQueryClient();
  }
  // Browser: use singleton pattern to keep the same query client
  return clientQueryClientSingleton ??= createQueryClient();
};

// This bug might be fixed now. Leaving this here for reference in case it comes up again.
// export const api: ReturnType<typeof createTRPCReact<AppRouter>> =
//   createTRPCReact<AppRouter>();

export const api = createTRPCReact<AppRouter>();
export function TRPCReactProvider(props: {
  children: React.ReactNode;
}) {
  const queryClient = getQueryClient();
  const [trpcClient] = useState(() => api.createClient({
    links: [loggerLink({
      enabled: op => process.env.NODE_ENV === "development" || op.direction === "down" && op.result instanceof Error
    }), httpBatchLink({
      transformer: transformer,
      url: getUrl()
    })]
  }));
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="TRPCReactProvider" data-sentry-source-file="react.tsx">
      <api.Provider client={trpcClient} queryClient={queryClient} data-sentry-element="unknown" data-sentry-source-file="react.tsx">
        {props.children}
      </api.Provider>
    </QueryClientProvider>;
}